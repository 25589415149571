<template>
  <f7-page class="merchantview-page" infinite :infinite-distance="50" :infinite-preloader="showPreloader" @infinite="loadMore">
    <template #fixed>
      <ProductNavigationComponent :title="$t.getTranslation('LBL_SHOP')" type="back" searchname="searchmerchantproductlist" :cart="false" @searched="onSearch" />
    </template>

    <section class="merchantview-container">
      <div class="merchant-profile">
        <div class="image">
          <img :src="$h.getImage(merchantInfo?.profileImage, 'MERCHANT')" :alt="$t.getTranslation('LBL_MERCHANT_IMAGE')" loading="lazy" />
        </div>
        <div class="info">
          <div class="counters">
            <div class="counter">
              <strong>{{ $h.formatDisplayNumber(merchantInfo?.TotalFollower || 0) }}</strong>
              <span>{{ $t.getTranslation("LBL_FOLLOWERS") }}</span>
            </div>
            <div class="counter">
              <strong>{{ $h.formatDisplayNumber(merchantInfo?.TotalProducts || 0) }}</strong>
              <span>{{ $t.getTranslation("LBL_PRODUCTS") }}</span>
            </div>
            <!-- <div class="counter">
              <strong>{{ $h.formatDisplayNumber(merchantInfo?.TotalReviews || 0) }}</strong>
              <span>{{ $t.getTranslation("LBL_REVIEWS") }}</span>
            </div> -->
          </div>
          <div class="button-container">
            <f7-button fill :disabled="!$h.isUserLogin() || $h.checkFollowDisabled(merchantInfo?.MerchantKey)" @click="followToggle(merchantInfo?.MerchantKey)">
              {{ merchantInfo?.IsFollow === 1 ? $t.getTranslation("LBL_UNFOLLOW") : $t.getTranslation("LBL_FOLLOW") }}
            </f7-button>
            <!-- <f7-button>
              <font-awesome-icon :icon="['far', 'comments']" fixed-width />
              Chat
            </f7-button> -->
          </div>
        </div>
      </div>
      <div class="merchant-info">
        <h1>{{ merchantInfo.MerchantName }}</h1>
        <!-- <p class="content" v-html="merchantInfo.MerchantDescription"></p> -->
      </div>
      <div class="merchant-tabs">
        <f7-toolbar tabbar class="tablinks">
          <!-- <f7-link tab-link="#tabHome">{{ $t.getTranslation("LBL_HOME") }}</f7-link> -->
          <f7-link tab-link="#tabProducts" tab-link-active>{{ $t.getTranslation("LBL_PRODUCTS") }}</f7-link>
          <f7-link tab-link="#tabInfo">{{ $t.getTranslation("LBL_MERCHANT_INFO") }}</f7-link>
        </f7-toolbar>

        <f7-tabs>
          <f7-tab id="tabHome" class="page-content merchant-home-tab">
            <BannerListComponent :f7route="f7route" :f7router="f7router" type="MERCHANT" />
            <CategoryListComponent v-if="merchantInfo.SupplierCode" :supplier-code="merchantInfo.SupplierCode" />
          </f7-tab>
          <f7-tab id="tabProducts" class="page-content merchant-product-tab" tab-active>
            <ProductListComponent v-if="merchantInfo.SupplierCode" ref="productListComponent" :supplier-code="merchantInfo.SupplierCode" :options="true" />
          </f7-tab>
          <f7-tab id="tabInfo" class="page-content">
            <p></p>
            <p class="content" v-html="merchantInfo.MerchantDescription"></p>
          </f7-tab>
        </f7-tabs>
      </div>
    </section>
  </f7-page>
</template>

<script>
import { defineComponent, ref, onMounted, inject, defineAsyncComponent } from "vue";
import { f7 } from "framework7-vue";
import { useStore } from "@/store";
import { get, post } from "@/utils/axios";

import ProductNavigationComponent from "@/components/navigations/ProductNavigationComponent.vue";

// import BannerListComponent from "@/components/BannerListComponent.vue";
// import CategoryListComponent from "@/components/CategoryListComponent.vue";
// import ProductListComponent from "@/components/ProductListComponent.vue";

const BannerListComponent = defineAsyncComponent(() => import(/* webpackChunkName: "banner-list" */ /* webpackMode: "lazy" */ "@/components/BannerListComponent.vue"));
const CategoryListComponent = defineAsyncComponent(() => import(/* webpackChunkName: "category-list" */ /* webpackMode: "lazy" */ "@/components/CategoryListComponent.vue"));
const ProductListComponent = defineAsyncComponent(() => import(/* webpackChunkName: "product-list" */ /* webpackMode: "lazy" */ "@/components/ProductListComponent.vue"));

export default defineComponent({
  name: "MerchantViewPage",
  components: {
    ProductNavigationComponent,
    BannerListComponent,
    CategoryListComponent,
    ProductListComponent,
  },
  props: { f7router: Object, f7route: Object },
  setup(props) {
    const store = useStore();

    const $t = inject("$translation");

    const productListComponent = ref(false);
    const showPreloader = ref(false);

    const merchantInfo = ref(false);
    const merchantKey = ref(false);

    const getData = async () => {
      let ret = await get(`/merchant/view?&LanguageCode=${$t.getLanguage()}&MerchantKey=${merchantKey.value}`);

      let profileImage = _.filter(ret.MerchantImages, function (item, index) {
        return item.FieldName == "ProfileBannerImage";
      });

      if (profileImage.length > 0) {
        ret.profileImage = profileImage[0].FileName;
      }

      merchantInfo.value = ret;
    };

    onMounted(() => {
      if (props?.f7route?.params?.code) {
        merchantKey.value = props?.f7route?.params?.code;
        getData();
      }
    });

    // f7.on("pageBeforeIn", () => {
    //   if (props?.f7route?.params?.code) {
    //     merchantKey.value = props?.f7route?.params?.code;
    //     getData();
    //   }
    // });

    const followToggle = async (MerchantKey) => {
      await post("/follow/toggle", { FollowKey: MerchantKey, Type: "MERCHANT" });
      getData();
    };

    return {
      merchantInfo,
      followToggle,
      productListComponent,
      showPreloader,
      store,
    };
  },
  methods: {
    async loadMore() {
      this.showPreloader = true;
      await this.$refs.productListComponent.loadMore();
      this.showPreloader = false;
    },
    async onSearch(data) {
      this.$refs.productListComponent.search(data);
    },
  },
});
</script>

<style>
.product-container {
  margin-top: 10px;
}
</style>
